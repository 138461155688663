<!-- 绑定手机 -->
<template>
  <div class="_nicheng">
    <div class="flex align-items">
      <div class="ziti">{{ "更换手机:" }}</div>
      <div class="account">
        <el-input
          v-model="input"
          placeholder="请输入您的新昵称"
          :disabled="jinyong"
        ></el-input>
      </div>
      <div class="fontOne" v-if="xiusta == 0" @click="bianji">{{ "编辑" }}</div>
    </div>
    <div class="flex anniu" v-if="xiusta == 1">
      <div class="you">
        <!-- 用旧手机发送验证码 -->
        <!-- <div class="fasong" v-if="mimabuzhou == 0">
          <div class="fontTwo">{{ `手机号 : ${moblie}` }}</div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="phoneAuthCode"
                >发送验证码</el-button
              >
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div> -->
        <!-- 输入新手机号和旧手机的验证码 -->
        <!-- v-else-if="mimabuzhou == 1" -->
        <div>
          <div class="diyici">
            <el-input
              v-model="onemoblie"
              placeholder="请输入新的手机号"
            ></el-input>
          </div>

          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input v-model="oldauth" placeholder="请输入验证码"></el-input>
            </div>
            <div class="btnfour">
              <el-button
                type="primary"
                v-if="timeNum == 0"
                @click="phoneAuthCode"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNum }}
              </el-button>
            </div>
          </div>

          <div class="flex">
            <div class="btnThree">
              <!-- VerificationNumber -->
              <el-button type="primary" @click="newNumber"
                >确定</el-button
              >
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
        <!-- 获取新手机的验证码 -->
        <!-- <div v-else>
          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input
                v-model="newauth"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="btnfour">
              <el-button
                type="primary"
                v-if="timeNums == 0"
                @click="VerificationNumber"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNums }}
              </el-button>
            </div>
          </div>
          <div class="flex">
            <div class="btnOne">
              <el-button type="primary" @click="newNumber">确定</el-button>
            </div>
            <div class="btnTwo">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/bianji";
import Bus from "@/assets/ligature.js";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      loading: null,
      input: "", //手机号
      xiusta: 0,
      jinyong: true, //未点击编辑时禁用富文本
      moblie: "", //手机号备用
      mimabuzhou: 0, //修改手机号码步骤 0发送旧手机验证码 1输入新的手机号,输入旧手机的验证码 ,然后通过新手机号和旧手机号的验证码获取新手机的验证码
      timeNum: 0, //倒计时
      timer: null, //定时器
      onemoblie: "", //新手机号
      oldauth: "", //旧手机验证码
      timers: null, //第二个定时器
      timeNums: 0, //第二个倒计时
      newauth: "", //新手机验证码
    };
  },
  watch: {
    user: function (vla, old) {
      this.moblie = vla == null ? "" : vla.mobile;
      let a = vla == null ? "" : vla.mobile;
      this.input = a.slice(0, 3) + "****" + a.slice(7, 11);
    },
  },
  mounted() {
    this.moblie = this.user == null ? "" : this.user.mobile;
    let a = this.user == null ? "" : this.user.mobile;
    this.input = a.slice(0, 3) + "*****" + a.slice(8, 11);
  },
  methods: {
    // 验证新手机号码
    async newNumber() {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changemobile({
        mobile: this.onemoblie,
        captcha: this.oldauth,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$parent.yong();
          this.$parent.fyonghu();
          this.loading.close();
          this.quxiao();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 新手机号和旧手机号的验证码
    async VerificationNumber() {
      if (this.onemoblie == "") {
        return;
      }
      if (this.oldauth == "") {
        return;
      }

      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.sms({
        mobile: this.onemoblie,
        captcha: this.oldauth,
        event: "changemobile",
      }).then((res) => {
        if (res.code == 1) {
          this.timeNum = 0;
          this.mimabuzhou = 2;
          clearTimeout(this.timers);
          this.timeNums = 60;
          this.loading.close();
          this.jishi();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 在有手机号的情况下修改手机号
    // 用旧手机发送验证码
    // 必须传profile
    async phoneAuthCode() {
      if(this.onemoblie == ''){
        this.$message({
            message: "请输入手机号!",
            type: "warning",
          });
          return
      }
      this.timeNum = 0;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.sms({
        mobile: this.onemoblie,
        event: "changemobile",
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "验证码已发送,请注意查收!",
            type: "success",
          });
          this.loading.close();
          this.mimabuzhou = 1;
          // 开始60s计时器
          clearTimeout(this.timer);
          this.timeNum = 60;
          this.sixty();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 验证码60s倒计时
    sixty() {
      if (this.timeNum != 0) {
        this.timer = setTimeout(() => {
          this.timeNum--;
          this.sixty();
        }, 1000);
      }
    },
    // 验证码60s倒计时
    jishi() {
      if (this.timeNums > 0) {
        this.timers = setTimeout(() => {
          this.timeNums--;
          this.jishi();
        }, 1000);
      } else {
        this.timeNums = 0;
      }
    },
    // 启用编辑
    bianji() {
      // this.jinyong = false
      this.xiusta = 1;
      let a = this.user == null ? "" : this.user.mobile;
      this.input = a
    },
    // 取消修改
    quxiao() {
      this.mimabuzhou = 0;
      this.oldauth = "";
      this.newauth = "";
      this.onemoblie = "";
      // this.jinyong = true
      this.xiusta = 0;

      this.moblie = this.user == null ? "" : this.user.mobile;
      let a = this.user == null ? "" : this.user.mobile;
      this.input = a.slice(0, 3) + "*****" + a.slice(8, 11);
    },
  },
};
</script>

<style scoped lang="scss">
._nicheng {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 25px;
}

.account {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    color: #606266 !important;
  }
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #2670c5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

.anniu {
  margin-top: 15px;
  padding-left: 88px;
}

.btnOne {
  // margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.you {
  padding-left: 10px;

  .fontTwo {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnThree {
  margin-top: 10px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.diyici {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;
  margin-bottom: 15px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}

.yanzheng {
  width: 200px !important;
  margin-right: 15px;
}

.btnfour {
  // margin-left: 15px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}
</style>