<!-- 个人信息编辑 -->
<template>
  <div class="bianji">
    <!-- 导航标签 -->
    <!-- <div class="flex biao align-items">
            <div>{{ '当前位置 : ' }}</div>
            <div style="opacity: 0;">{{ '0' }}</div>
            <div class="cursor_pointer">{{ '首页' }}</div>
            <div class="zuojian">
                <img src="../../assets/img/xinban/zuojian.png" class="w-full h-full" alt="">
            </div>
            <div class="cursor_pointer yanse">{{ '修改个人信息' }}</div>
        </div> -->
    <!-- 发布主题与撤回 -->
    <!-- <div class="chehui flex space-between align-items">
            <div class="flex">
                <el-button type="primary" @click="fabu">发表留言</el-button>
            </div>
            <div class="flex align-items che cursor_pointer" @click="fanhui">
                <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="">
                <div>{{ '返回列表' }}</div>
            </div>
        </div> -->
    <!--  -->
    <div class="neirong">
      <div class="flex align-items">
        <div class="icon">
          <img
            src="../../assets/img/xinban/gerenxinxi.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div class="fontOne">{{ "账号设置" }}</div>
      </div>
      <!-- 表格 -->
      <div class="flex w-full justify-content">
        <div class="chang">
          <!-- 修改头像 -->
          <div class="flex mb49">
            <div class="ziti">{{ "头像:" }}</div>
            <!-- 头像框 -->
            <div class="xiangkuang">
              <img
                :src="yonghu == null ? '' : yonghu.netizen_avatar"
                v-if="yonghu == null ? '' : yonghu.netizen_avatar != ''"
                class="w-full h-full"
                alt=""
              />
              <img
                src="../../assets/img/one/weizhiyonghu.png"
                v-else
                class="w-full h-full"
                alt=""
              />
              <div class="chongzhi" @click="chongzhiimg">
                <img
                  src="../../assets/img/xinban/chongzhi.png"
                  class="w-full h-full"
                  alt=""
                />
              </div>
              <div class="xiugai" @click="xiugai">
                <img
                  src="../../assets/img/xinban/qianbi.png"
                  class="w-full h-full"
                  alt=""
                />
              </div>
            </div>
            <!-- 支持类型 -->
            <div class="zhichi">{{ "支持上传照片类型：jpg、png" }}</div>
          </div>
          <!-- 修改昵称 -->
          <div class="w-full mb43">
            <nicheng :user="yonghu"></nicheng>
          </div>
          <!-- 修改用户名 -->
          <div class="w-full mb43">
            <username :user="yonghu"></username>
          </div>
          <!-- 修改性别
                    <div class="w-full mb43">
                        <xingbie :user="yonghu"></xingbie>
                    </div> -->
          <!-- 修改密码 -->
          <div class="w-full mb43">
            <mima :user="yonghu"></mima>
          </div>
          <!-- 绑定手机 -->
          <div class="w-full mb43">
            <shouji :user="yonghu"></shouji>
          </div>
          <!-- 绑定邮箱 -->
          <div class="w-full mb43">
            <youxiang :user="yonghu"></youxiang>
          </div>
          <div class="w-full mb53">
            <mibao user="yonghu"></mibao>
          </div>
          <!-- 绑定第三方账号 -->
          <div class="w-full mb319">
            <disanfang ref="disan" :user="yonghu"></disanfang>
          </div>
          <!-- 个人签名 -->
          <div v-show="smx != 0">{{ "孙明轩" }}</div>
        </div>
      </div>
    </div>
    <!-- 用户修改头像 -->
    <avatarCropper
      :dialogVisible.sync="profilePhoto"
      @yong="yong"
    ></avatarCropper>
  </div>
</template>

<script>
import API from "@/api/modules/bianji";
import Cookies from "js-cookie";
// 修改头像
import avatarCropper from "./photo/index.vue"; // 修改头像
import nicheng from "./nicheng/index.vue"; // 修改昵称
// import xingbie from './xingbie/index.vue' // 修改性别
import mima from "./mima/index.vue"; // 修改密码
import shouji from "./shouji/index.vue"; // 修改手机号
import youxiang from "./youxiang/index.vue"; // 绑定邮箱
import username from "./usernamne/index.vue";
import mibao from "./mibao/index.vue";//重置密保
import disanfang from "./disanfang/index.vue"; //绑定或者解绑第三方账号
import Bus from "@/assets/ligature.js";
export default {
  components: {
    avatarCropper,
    nicheng,
    // xingbie,
    mima,
    shouji,
    youxiang,
    disanfang,
    username,
    mibao
  },
  data() {
    return {
      yonghu: null,
      profilePhoto: false, //修改头像弹框
      smx: 0,
    };
  },
  mounted() {
    localStorage.setItem("path", this.$route.path);
    // 先判定是否有token 如果为空
    // window.localStorage.setItem('sendebang', a)
    let aaa = window.localStorage.getItem("sendebang");
    // window.localStorage.removeItem('sendebang')
    if (aaa == undefined) {
      this.$store.state.path = this.$route.path;
      localStorage.setItem("path", this.$route.path);
      this.$router.push("/login");
      return;
    }
    this.yong();
  },
  methods: {
    fyonghu() {
      this.$parent.yonghu();
    },
    fanhui() {
      this.jumps("persomal", this.$store.state.loginMessage);
    },
    // 进入发布页面
    fabu() {
      this.jumps("issue", this.$store.state.loginMessage);
    },
    // 打开修改头像
    xiugai() {
      this.profilePhoto = true;
    },
    chongzhiimg(){
      this.$confirm('是否重置头像？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         API.chongzhiavatar().then((res) => {
            if (res.code == 1) {
              Bus.$emit("yonghuxinxi");
              this.fyonghu()
              this.yong()
              // this.yonghu = res.netizen_avatar;
            }
          });
         
        }).catch(() => {
               
        });

    },
    
    // 用户
    async yong() {
      let res = await API.user().then((res) => {
        if (res.code == 1) {
          this.yonghu = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bianji {
  width: 100%;
  height: 100%;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

.neirong {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  padding: 27px 33px 0 56px;
  margin-top: 15px;

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .fontOne {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 500;
  }

  .chang {
    width: 550px;
    margin-top: 49px;
  }

  .ziti {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 60px;
  }

  .xiangkuang {
    width: 110px;
    height: 110px;
    padding: 6px;
    position: relative;
    margin-right: 37px;
    background: #ffffff;
    border: 1px solid #d6dbe3;
  }

  .zhichi {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #c90e0e;
    letter-spacing: 0;
    font-weight: 400;
    display: flex;
    align-items: end;
  }

  .xiugai {
    width: 28px;
    height: 28px;
    position: absolute;
    right: calc(0% - 14px);
    top: calc(0% - 14px);
    cursor: pointer;
  }
  .chongzhi{
    width: 28px;
    height: 28px;
    position: absolute;
    left: calc(0% - 14px);
    top: calc(0% - 14px);
    cursor: pointer;
  }
}

.mb49 {
  margin-bottom: 49px;
}
.mb43 {
  margin-bottom: 43px;
}
.mb53 {
  margin-bottom: 53px;
}
.mb319 {
  margin-bottom: 319px;
}
</style>