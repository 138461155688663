<!-- 绑定或者解绑第三方账号 -->
<template>
  <div class="_disanfnag">
    <div class="flex align-items">
      <div class="ziti">{{ "绑定第三方账号:" }}</div>
      <div class="weixin flex cursor_pointer" v-if="xin == 0" @click="Sweixin">
        <div class="icon">
          <img
            src="../../../assets/img/one/weixin.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div>{{ "绑定微信" }}</div>
      </div>
      <div
        class="weixin flex cursor_pointer"
        v-else
        @click="unbind('wechatpc')"
      >
        <div class="icon">
          <img
            src="../../../assets/img/one/weixin.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div>{{ "解除绑定" }}</div>
      </div>
      <div class="weibo flex cursor_pointer" v-if="bo == 0" @click="Sweibo">
        <div class="icon">
          <img
            src="../../../assets/img/one/weibo.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div>{{ "绑定微博" }}</div>
      </div>
      <div class="weibo flex cursor_pointer" v-else @click="unbind('weibo')">
        <div class="icon">
          <img
            src="../../../assets/img/one/weibo.png"
            class="w-full h-full"
            alt=""
          />
        </div>
        <div>{{ "解绑微博" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/bianji";
export default {
  // props: {
  //   user: Object,
  // },
  data() {
    return {
      xin: 0, //微信绑定状态 0,未绑定 1,已绑定
      bo: 0, //微薄绑定状态 0,未绑定 1,已绑定
      loading: null,
    };
  },
  watch: {
    // user: function (vla, old) {
    //   this.xin = vla == null ? 1 : vla.third.wechatpc.isbind;
    //   this.bo = vla == null ? 1 : vla.third.weibo.isbind;
    // },
  },
  created() {
    // 当页面指向这个页面时重新回跳
    if (this.$route.path == "/editing") {
      this.$store.state.shezhi = 1;
      this.jumps("persomal", this.$store.state.loginMessage);
    }
  },
  mounted() {
    // this.xin = this.user == null ? 1 : this.user.third.wechatpc.isbind;
    // this.bo = this.user == null ? 1 : this.user.third.weibo.isbind;
    this.yong();
    // 判定是否为扫码绑定
    // 判定是否是扫码后的回调
    let bd = sessionStorage.getItem("bd");
    // 如果是绑定登录则跳入账号设置
    if (bd != null) {
      this.Scancode();
    }
  },
  methods: {
    // 用户
    async yong() {
      let res = await API.user().then((res) => {
        // console.log(res,'啥大电话撒和');
        if (res.code == 1) {
          this.$nextTick(() => {
            this.xin = res.data.third.wechatpc.isbind;
            this.bo = res.data.third.weibo.isbind;
          });
          this.loading = this.$loading({
            lock: true,
            background: "rgba(0, 0, 0, 0.0)",
          });
          // console.log(this.bo,res.data.third.weibo.isbind,'1号');
          this.loading.close();
        }
      });
    },
    // 解绑微信
    async unbind(str) {
      // console.log(this.user.third.weibo, '111112');
      this.loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.unbind({
        platform: str,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          // this.$parent.yong();
          setTimeout(() => {
            this.yong();
          }, 2000);
          // this.$parent.fyonghu();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 获取微信的第三方
    async Sweixin() {
      // 代表微信登录还是微薄登录
      sessionStorage.setItem("bd", "wechatpc");
      let res = await API.getAuthorizeUrl({
        platform: "wechatpc",
        kind: "bind",
      }).then((res) => {
        if (res.code == 1) {
          window.location.replace(res.data.url);
          this.$parent.fyonghu();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          sessionStorage.removeItem("bd");
        }
      });
    },
    // 获取微薄第三方
    async Sweibo() {
      // 代表微信登录还是微薄登录
      sessionStorage.setItem("bd", "weibo");
      let res = await API.getAuthorizeUrl({
        platform: "weibo",
        kind: "bind",
      }).then((res) => {
        if (res.code == 1) {
          window.location.replace(res.data.url);
          this.$parent.fyonghu();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          sessionStorage.removeItem("bd");
        }
      });
    },
    // 第三方的回调
    // 获取扫码登录的信息
    async Scancode() {
      let bd = sessionStorage.getItem("bd");
      let q = {};
      let test = window.location.href;
      test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));

      if (q.code == undefined) {
        sessionStorage.removeItem("bd");
        return;
      }
      let res = await API.callback({
        code: q.code,
        state: q.state,
        platform: bd,
      }).then((res) => {
        // console.log(res, `${bd}登录`);
        if (res.code == 1) {
          this.loading = this.$loading({
            lock: true,
            background: "rgba(0, 0, 0, 0.0)",
          });
          // this.$message({
          //   message: res.msg,
          //   type: "success",
          // });
          setTimeout(() => {
            this.loading.close();
            this.$nextTick(() => {
              if (bd == "weibo") {
                this.bo = 1;
              } else {
                // console.log(bd, "sjh ");
                this.xin = 1;
              }
            });
          }, 2000);
          // setTimeout(() => {
          //   this.yong();
          // }, 2000);
        } else {
          this.loading.close();
          this.$alert(res.msg, "绑定提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          // this.$message({
          //   message: res.msg,
          //   type: "warning",
          // });
        }
      });
      sessionStorage.removeItem("bd");
    },
  },
};
</script>

<style scoped lang="scss">
._disanfnag {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 30px;
}

.weixin {
  margin-right: 61px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 9px;
}
</style>