<!-- 重置密保问题 -->
<template>
  <div class="_nicheng">
    <div class="flex align-items">
      <div class="ziti">{{ "密保问题:" }}</div>
      <div class="account">
        <el-input
          v-model="input"
          placeholder="请输入您的新昵称"
          :disabled="jinyong"
        ></el-input>
      </div>
      <div class="fontOne" v-if="xiusta == 0" @click="bianji">{{ "重置" }}</div>
    </div>
    <div v-if="xiusta == 1">
      <!-- 密保问题 -->
      <div class="miwen">
        <el-select v-model="wenti" placeholder="请选择密保问题" @change="huode">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 密保答案 -->
      <div class="mida">
        <el-input
          class="inline-input"
          v-model.trim="danan"
          placeholder="请输入答案"
        >
        </el-input>
      </div>
      <div class="flex anniu">
        <div class="btnOne">
          <el-button type="primary" @click="xname">确定</el-button>
        </div>
        <div class="btnTwo">
          <el-button type="info" plain @click="quxiao">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import API from "@/api/modules/bianji";
import Bus from "@/assets/ligature.js";
export default {
  //   props: {
  //     user: Object,
  //   },
  data() {
    return {
      input: "**********", //密保问题
      xiusta: 0,
      jinyong: true, //未点击编辑时禁用富文本
      loading: null,
      wenti: "",
      danan: "",
      value: "",
      options: [
        {
          value: "1",
          label: "母亲的名字",
        },
        {
          value: "2",
          label: "爷爷的名字",
        },
        {
          value: "3",
          label: "父亲的手机号码",
        },
        {
          value: "4",
          label: "您其中一位老师的名字",
        },
        {
          value: "5",
          label: "您个人计算机的型号",
        },
        {
          value: "6",
          label: "您最喜欢的餐馆名称",
        },
        {
          value: "7",
          label: "驾驶执照最后四位数字",
        },
      ],
    };
  },
  watch: {
    //   user: function (vla, old) {
    //     this.input = vla == null ? "" : vla.netizen_nickname;
    //   },
  },
  mounted() {
    //   this.input = this.user == null ? "" : this.user.netizen_nickname;
  },
  methods: {
    huode(e) {
      this.value = e;
    },
    async xname() {
      if (this.value == "") {
        this.$message({
          message: "请选择问题",
          type: "warning",
        });
        return;
      }
      if (this.danan == "") {
        this.$message({
          message: "请输入密保答案",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changequestion({
        question: this.value,
        answer: this.danan,
      })
        .then((res) => {
          if (res.code == 1) {
            this.loading.close();
            this.wenti = "";
            this.danan = "";
            this.value = "";
            this.xiusta = 0;
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          this.loading.close();
          this.$message({
            message: '密保错误请重试',
            type: "warning",
          });
        });
    },
    // 启用编辑
    bianji() {
      this.xiusta = 1;
    },
    // 取消修改
    quxiao() {
      this.xiusta = 0;
    },
  },
};
</script>
  
  <style scoped lang="scss">
._nicheng {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 25px;
}

.account {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    color: #606266 !important;
  }
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #2670c5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

.anniu {
  margin-top: 15px;
  padding-left: 88px;
}

.btnOne {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}
.miwen {
  width: 363px;
  margin-left: calc(76.34px + 25px);
  margin-top: 15px;
  margin-bottom: 15px;
  ::v-deep(.el-select) {
    width: 100%;
  }
  :deep(.el-input__inner) {
    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.mida {
  width: 363px;
  margin-left: calc(76.34px + 25px);
  :deep(.el-input__inner) {
    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>