// 编辑和人信息页面专用接口
import request from '../request'

export default class Bianji {
    // 用户个人信息
    static user() {
        return request.get("/api/user/index")
    }
    /**
* 上传图片
* @param {*} params
* @returns
*/
    static upload(data) {
        return request.post('/api/common/upload', data)
    }
    /**
    * 修改头像
    * @param {*} params
    * @returns
    */
    static changeavatar(data) {
        return request.post('/api/user/changeavatar', data)
    }
    /**
    * 修改昵称
    * @param {*} params
    * @returns
    */
    static changenickname(data) {
        return request.post('/api/user/changenickname', data)
    }
    /**
* 修改性别
* @param {*} params
* @returns
*/
    static changegender(data) {
        return request.post('/api/user/changegender', data)
    }
    /**
* 手机发送验证码
* @param {*} params
* @returns
*/
    static sms(data) {
        return request.post('/api/sms/send', data)
    }
    /**
* 修改密码
* @param {*} params
* @returns
*/
    static changepwd(data) {
        return request.post('/api/user/changepwd', data)
    }
    /**
* 修改手机号
* @param {*} params
* @returns
*/
    static changemobile(data) {
        return request.post('/api/user/changemobile', data)
    }
    /**
* 邮箱验证码
* @param {*} params
* @returns
*/
    static email(data) {
        return request.post('/api/ems/send', data)
    }
    /**
* 修改邮箱
* @param {*} params
* @returns
*/
    static changeemail(data) {
        return request.post('/api/user/changeemail', data)
    }
    /**
* 微信扫码登录
* @param {*} params 
* @returns 
*/
    static getAuthorizeUrl(params) {
        return request.get("/api/third/getAuthorizeUrl", {
            params
        })
    }
    /**
* 第三方授权回调
* @param {*} params
* @returns
*/
    static callback(data) {
        return request.post('/api/third/callback', data)
    }
    /**
* 绑定第三方
* @param {*} params
* @returns
*/
    static bind(data) {
        return request.post('/api/third/bind', data)
    }
    /**
    * 解绑第三方
    * @param {*} params
    * @returns
    */
    static unbind(data) {
        return request.post('/api/third/unbind', data)
    }
    /**
* 敏感词查询
* @param {*} params 
* @returns 
*/
    static sensitive(params) {
        return request.get("/api/v1.articlecurrency/sensitive", {
            params
        })
    }
    /**
   * 修改用户名
   * @param {*} params
   * @returns
   */
    static changebyname(data) {
        return request.post('/api/user/changebyname', data)
    }
    /**
 * 修改密保
 * @param {*} params
 * @returns
 */
    static changequestion(data) {
        return request.post('/api/user/changequestion', data)
    }
       /**
 * 修改密保
 * @param {*} params
 * @returns
 */
       static chongzhiavatar(data) {
        return request.post('/api/user/resetavatar', data)
    }
}