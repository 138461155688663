<!-- 修改昵称 -->
<template>
  <div class="_nicheng">
    <div class="flex align-items">
      <div class="ziti">{{ "用户名:" }}</div>
      <div class="account">
        <el-input
          v-model="input"
          placeholder="请输入您的新用户名"
          :disabled="jinyong"
        ></el-input>
      </div>
      <div class="fontOne" v-if="xiusta == 0" @click="bianji">{{ "编辑" }}</div>
    </div>
    <div class="flex anniu" v-if="xiusta == 1">
      <div class="btnOne">
        <el-button type="primary" @click="xname">确定</el-button>
      </div>
      <div class="btnTwo">
        <el-button type="info" plain @click="quxiao">取消</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import API from "@/api/modules/bianji";
import Bus from "@/assets/ligature.js";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      input: "", //昵称
      xiusta: 0,
      jinyong: true, //未点击编辑时禁用富文本
      loading:null,
      host:'',
    };
  },
  watch: {
    user: function (vla, old) {
      this.input = vla == null ? "" : vla.byname;
    },
  },
  mounted() {
    this.input = this.user == null ? "" : this.user.byname;
  },
  methods: {
    async xname() {
      if (this.input.length < 2) {
        this.$message({
          message: "用户名长度不能低于2个字符",
          type: "warning",
        });
        return;
      }
      if (this.input.length > 20) {
        this.$message({
          message: "用户名长度不能大于20个字符",
          type: "warning",
        });
        return;
      }
      await API.sensitive({
        info: this.input,
      }).then((res) => {
        this.loading = this.$loading({
          lock: true,
          // text: "Loading",
          // spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.0)",
        });
        if (res.code == 1) {
          let res = API.changebyname({
            byname: this.input,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$parent.yong();
              this.$parent.fyonghu();
              // 搜素关键字:头像刷新
              Bus.$emit("yonghuxinxi");
              this.quxiao();
              this.loading.close();
            } else {
              this.loading.close();
              this.$message({
                message: res.msg,
                type: "warning",
              });
              this.input = this.host
            }
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 启用编辑
    bianji() {
      this.jinyong = false;
      this.xiusta = 1;
      this.host = this.input
    },
    // 取消修改
    quxiao() {
      this.jinyong = true;
      this.xiusta = 0;
      this.input = this.host
    },
  },
};
</script>
  
  <style scoped lang="scss">
._nicheng {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 41px;
}

.account {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    color: #606266 !important;
  }
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #2670c5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

.anniu {
  margin-top: 15px;
  padding-left: 88px;
}

.btnOne {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}
</style>