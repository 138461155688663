<!-- 修改密码 -->
<template>
  <div class="_nicheng">
    <div class="flex align-items">
      <div class="ziti">{{ "密码:" }}</div>
      <div class="account">
        <el-input
          v-model="input"
          placeholder="请输入您的新密码"
          :disabled="jinyong"
        ></el-input>
      </div>
      <div class="fontOne" v-if="xiusta == 0" @click="bianji">{{ "编辑" }}</div>
    </div>
    <div class="anniu" v-if="xiusta == 1">
      <!-- 已有密码 -->
      <div class="you" v-if="mimasta == 1">
        <div class="fasong" v-if="mimabuzhou == 0">
          <div class="fontTwo">{{ `手机号 : ${moblie}` }}</div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="yanzhengma"
                >发送验证码</el-button
              >
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- 新密码 -->
          <div class="diyici">
            <el-input
              v-model="onepass"
              placeholder="请输入您的新密码"
              show-password
            ></el-input>
          </div>
          <div class="diyici">
            <el-input
              v-model="twopass"
              placeholder="请再次输入您的新密码"
              show-password
            ></el-input>
          </div>
          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input
                v-model="auth"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="btnfour">
              <el-button type="primary" v-if="timeNum == 0" @click="yanzhengma"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNum }}
              </el-button>
            </div>
          </div>
          <!-- 校验 -->
          <div class="jiaoyan">
            <div class="flex align-items">
              <i :class="{ acto: pnum == 1 }" class="el-icon-success ifont"></i>
              <span :class="{ acttwo: pnum == 1 }" class="pafont"
                >密码由6-20位组成</span
              >
            </div>
            <div class="flex" style="margin-top: 10px">
              <i
                :class="{ actthree: pnums == 1 }"
                class="el-icon-success ifont"
              ></i>
              <span :class="{ actfour: pnums == 1 }" class="pafont"
                >密码满足字母或特殊字符串的其中一种</span
              >
            </div>
            <div class="flex align-items" style="margin-top: 10px">
              <i
                :class="{ acttfive: pnumss == 1 }"
                class="el-icon-success ifont"
              ></i>
              <span :class="{ actsix: pnumss == 1 }" class="pafont"
                >两次密码输入一致</span
              >
            </div>
          </div>
          <div class="flex">
            <div class="btnOne">
              <el-button type="primary" @click="submitPass">确定</el-button>
            </div>
            <div class="btnTwo">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mei" v-else>
        <div class="diyici">
          <el-input
            v-model="passone"
            placeholder="请输入您的新密码"
            show-password
          ></el-input>
        </div>
        <div class="diyici">
          <el-input
            v-model="passtwo"
            placeholder="请输入您的新密码"
            show-password
          ></el-input>
        </div>
        <!-- 校验 -->
        <div class="jiaoyan">
          <div class="flex align-items">
            <i :class="{ acto: snum == 1 }" class="el-icon-success ifont"></i>
            <span :class="{ acttwo: snum == 1 }" class="pafont"
              >密码由6-20位组成</span
            >
          </div>
          <div class="flex" style="margin-top: 10px">
            <i
              :class="{ actthree: snums == 1 }"
              class="el-icon-success ifont"
            ></i>
            <span :class="{ actfour: snums == 1 }" class="pafont"
              >密码满足字母或特殊字符串的其中一种</span
            >
          </div>
          <div class="flex align-items" style="margin-top: 10px">
            <i
              :class="{ acttfive: snumss == 1 }"
              class="el-icon-success ifont"
            ></i>
            <span :class="{ actsix: snumss == 1 }" class="pafont"
              >两次密码输入一致</span
            >
          </div>
        </div>
        <div class="flex">
          <div class="btnOne">
            <el-button type="primary" @click="noneSubmit">确定</el-button>
          </div>
          <div class="btnTwo">
            <el-button type="info" plain @click="quxiao">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/bianji";
// import Bus from '@/assets/ligature.js'
import Cookies from "js-cookie";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      pnum: 0, //密码校验
      pnums: 0, //密码校验
      pnumss: 0, //密码校验
      snum: 0, //密码校验
      snums: 0, //密码校验
      snumss: 0, //密码校验
      loading: null,
      input: "", //密码
      xiusta: 0,
      jinyong: true, //未点击编辑时禁用富文本
      mimasta: 0, // 密码状态 0 未设置密码 1 已设置密码
      mimabuzhou: 0, //有密码的情况修改密码步骤,0 发送验证码 1,输入新密码
      moblie: "", //手机号
      timeNum: 0, //倒计时
      timer: null, //定时器
      onepass: "", //第一次输入的密码
      twopass: "", //第二次输入的密码
      auth: "", // 验证码 有密码的情况下修改密码
      passone: "", //没有密码的情况下输入密码
      passtwo: "", //没有密码的情况下再次输入密码
    };
  },
  watch: {
    user: function (vla, old) {
      this.mimasta = vla == null ? "1" : vla.set_pwd;
      this.moblie = vla == null ? "" : vla.mobile;
      if (this.mimasta == 0) {
        this.input = "未设置";
      } else {
        this.input = "******";
      }
    },
    onepass: function (vla, old) {
      if (vla.length < 6) {
        this.pnum = 0;
      }
      if (vla.length >= 6) {
        this.pnum = 1;
      }
      if (vla.length > 20) {
        this.pnum = 0;
      }
      const check = /[A-Za-z.!@#%]/;
      // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      if (check.test(vla)) {
        this.pnums = 1;
      } else {
        this.pnums = 0;
      }
      if (vla == this.twopass) {
        this.pnumss = 1;
      } else {
        this.pnumss = 0;
      }
    },
    twopass: function (vla, old) {
      if (vla == this.onepass) {
        this.pnumss = 1;
      } else {
        this.pnumss = 0;
      }
    },
    passone: function (vla, old) {
      if (vla.length < 6) {
        this.snum = 0;
      }
      if (vla.length >= 6) {
        this.snum = 1;
      }
      if (vla.length > 20) {
        this.snum = 0;
      }
      const check = /[A-Za-z.!@#%]/;
      // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      if (check.test(vla)) {
        this.snums = 1;
      } else {
        this.snums = 0;
      }
      if (vla == this.passtwo) {
        this.snumss = 1;
      } else {
        this.snumss = 0;
      }
    },
    passtwo: function (vla, old) {
      if (vla == this.passone) {
        this.snumss = 1;
      } else {
        this.snumss = 0;
      }
    },
  },
  mounted() {
    this.mimasta = this.user == null ? "0" : this.user.set_pwd;
    if (this.mimasta == 0) {
      this.input = "未设置";
    } else {
      this.input = "******";
    }
    this.moblie = this.user == null ? "" : this.user.mobile;
  },
  methods: {
    // 没有密码的情况下修改密码
    async noneSubmit() {
      if (this.passone == "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      if (this.passtwo == "") {
        this.$message({
          message: "请再次输入密码",
          type: "warning",
        });
        return;
      }
      const check = /[A-Za-z.!@#%]/;
      // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      if (check.test(this.passone)) {
        // console.log("ok");
      } else {
        this.$message({
          message: "密码满足字母或特殊字符串的其中一种!",
          type: "warning",
        });
        return;
      }
      if (this.passone != this.passtwo) {
        this.$message.error("两次输入密码不一致!");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changepwd({
        mobile: this.moblie,
        newpassword: this.passtwo,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loading.close();
          // window.localStorage.setItem('sendebang', a)
          // let aaa = window.localStorage.getItem('sendebang')
          window.localStorage.removeItem("sendebang");
          // Cookies.remove("sendebang");
          this.$router.push({
            path: `/login`,
            query: this.$store.state.loginMessage,
          });
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 修改密码
    async submitPass() {
      if (this.onepass == "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      if (this.twopass == "") {
        this.$message({
          message: "请再次输入密码",
          type: "warning",
        });
        return;
      }
      if (this.auth == "") {
        this.$message({
          message: "请输入验证码",
          type: "warning",
        });
        return;
      }
      const check = /[A-Za-z.!@#%]/;
      // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      if (check.test(this.onepass)) {
        // console.log("ok");
      } else {
        this.$message({
          message: "密码满足字母或特殊字符串的其中一种!",
          type: "warning",
        });
        return;
      }
      if (this.onepass != this.twopass) {
        this.$message.error("两次输入密码不一致!");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changepwd({
        mobile: this.moblie,
        newpassword: this.twopass,
        captcha: this.auth,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loading.close();
          // 清空数据
          // window.localStorage.setItem('sendebang', a)
          // let aaa = window.localStorage.getItem('sendebang')
          window.localStorage.removeItem("sendebang");
          // Cookies.remove("sendebang");
          this.$router.push({
            path: `/login`,
            query: this.$store.state.loginMessage,
          });
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 有密码的情况下发送验证码
    async yanzhengma() {
      this.timeNum = 0;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.sms({
        mobile: this.user.mobile,
        event: "changepwd",
      }).then((res) => {
        if (res.code == 1) {
          // 进入二阶段
          this.mimabuzhou = 1;
          // 如果有定时则关闭定时器
          clearTimeout(this.timer);
          // 定时器初始化
          this.timeNum = 60;
          // 启动定时
          this.sixty();
          this.loading.close();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 验证码60s倒计时
    sixty() {
      if (this.timeNum != 0) {
        this.timer = setTimeout(() => {
          this.timeNum--;
          this.sixty();
        }, 1000);
      }
    },
    // 启用编辑
    bianji() {
      // this.jinyong = false
      this.xiusta = 1;
      this.$parent.yong();
    },
    // 取消修改
    quxiao() {
      // this.jinyong = true
      this.xiusta = 0;
      this.onepass = "";
      this.twopass = "";
      this.auth = "";
      this.mimabuzhou = 0;
    },
  },
};
</script>

<style scoped lang="scss">
._nicheng {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 60px;
}

.account {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    color: #606266 !important;
  }
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #2670c5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

.anniu {
  margin-top: 15px;
  padding-left: 88px;
}

.btnOne {
  // margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.you {
  padding-left: 10px;

  .fontTwo {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.mei {
  padding-left: 12px;
}

.btnThree {
  margin-top: 10px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.diyici {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;
  margin-bottom: 15px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}

.yanzheng {
  width: 180px !important;
  margin-right: 15px;
}

.btnfour {
  // margin-left: 15px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}
.jiaoyan {
  margin-top: 15px;
  margin-bottom: 15px;
  .ifont {
    font-size: 20px;
    color: #888888;
  }
  .pafont {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #888888;
    margin-left: 5px;
  }
  .acto {
    color: #339933 !important;
  }

  .acttwo {
    color: #000 !important;
  }

  .actthree {
    color: #339933 !important;
  }

  .actfour {
    color: #000 !important;
  }
  .acttfive {
    color: #339933 !important;
  }
  .actsix {
    color: #000 !important;
  }
}
</style>