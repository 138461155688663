<!-- 绑定邮箱 -->
<template>
  <div class="_nicheng">
    <div class="flex align-items">
      <div class="ziti">{{ "绑定邮箱:" }}</div>
      <div class="account">
        <el-input
          v-model="input"
          placeholder="请输入您的新邮箱"
          :disabled="jinyong"
        ></el-input>
      </div>
      <div class="fontOne" v-if="xiusta == 0" @click="bianji">{{ "编辑" }}</div>
    </div>
    <div class="anniu" v-if="xiusta == 1">
      <!-- 没有邮箱 -->
      <div class="you" v-if="youxiang == ''">
        <div class="fasong">
          <div class="diyici">
            <el-input v-model="onepass" placeholder="请输入您的邮箱"></el-input>
          </div>
          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input
                v-model="auth"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="btnfour">
              <el-button type="primary" v-if="timeNum == 0" @click="emailAuth"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNum }}
              </el-button>
            </div>
          </div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="bindEmail">确定</el-button>
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 有邮箱的情况下,修改邮箱 -->
      <div class="mei" v-else>
        <!-- 第一步:获取旧的邮箱验证码 -->
        <div v-if="youxiangbuzhou == 0">
          <div class="fontTwo">{{ `邮箱 : ${youxiang}` }}</div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="XiuEmail">发送验证码</el-button>
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
        <!-- 第二步:输入新的邮箱和旧的邮箱验证码 -->
        <div v-else-if="youxiangbuzhou == 1">
          <div class="diyici">
            <el-input
              v-model="twopass"
              placeholder="请输入您的新邮箱"
            ></el-input>
          </div>
          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input
                v-model="oldauth"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="btnfour">
              <el-button type="primary" v-if="timeNum == 0" @click="XiuEmail"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNum }}
              </el-button>
            </div>
          </div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="newEmail">邮箱验证码</el-button>
            </div>
            <div class="btnTwo" style="margin-top: 10px">
              <el-button type="info" plain @click="quxiao">取消</el-button>
            </div>
          </div>
        </div>
        <!-- 第三步,验证新邮箱的验证码 -->
        <div v-else>
          <div class="flex">
            <div class="diyici yanzheng flex">
              <el-input
                v-model="newauth"
                placeholder="请输入您的验证码"
              ></el-input>
            </div>
            <div class="btnfour">
              <el-button type="primary" v-if="timeNums == 0" @click="newEmail"
                >发送验证码
              </el-button>
              <el-button type="primary" v-else
                >发送验证码{{ timeNums }}
              </el-button>
            </div>
          </div>
          <div class="flex">
            <div class="btnThree">
              <el-button type="primary" @click="bindNewEmail">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/bianji";
// import Bus from '@/assets/ligature.js'
import Cookies from "js-cookie";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      loading: null,
      input: "", //邮箱
      xiusta: 0,
      jinyong: true, //未点击编辑时禁用富文本
      youxiangbuzhou: 0, //有邮箱的情况下修改邮箱,1用旧的邮箱发送验证码,2输入新的邮箱和旧的邮箱的验证码,获取新的邮箱验证码,3验证新的邮箱验证码
      youxiang: "", //邮箱号
      timeNum: 0, //倒计时
      timer: null, //定时器
      timeNums: 0, //倒计时
      timers: null, //定时器
      onepass: "", //没有邮箱时输入邮箱
      twopass: "", //有邮箱的情况下输入新的邮箱
      auth: "", // 邮箱验证码
      oldauth: "", //旧邮箱的验证码
      newauth: "", //新的邮箱验证码
    };
  },
  watch: {
    user: function (vla, old) {
      this.youxiang = vla == null ? "1" : vla.email;
      let a = vla == null ? "" : vla.email;
      if (a == 0) {
        this.input = "未设置";
      } else {
        this.input = a.slice(0, 3) + "*****" + a.slice(8);
        // this.input = a
      }
    },
  },
  mounted() {
    this.youxiang = this.user == null ? "" : this.user.email;
    let a = this.user == null ? "" : this.user.email;
    if (a == "") {
      this.input = "未绑定";
    } else {
      this.input = a.slice(0, 3) + "*****" + a.slice(8);
      // this.input = a
    }
  },
  methods: {
    // 在有邮箱的情况下修改邮箱
    // 绑定新邮箱
    async bindNewEmail() {
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changeemail({
        email: this.twopass,
        captcha: this.newauth,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.loading.close();
          this.$parent.yong();
          this.quxiao();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 在有邮箱的情况下修改邮箱
    // 第二步:输入新的邮箱和旧的邮箱验证码,来获取新的邮箱验证码
    async newEmail() {
      this.timeNums = 0;
      if (this.twopass == "") {
        return;
      }
      if (this.oldauth == "") {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.email({
        email: this.twopass,
        event: "changeemail",
        captcha: this.oldauth,
      }).then((res) => {
        if (res.code == 1) {
          // 成功后进行下一步验证
          this.youxiangbuzhou = 2;
          // 开始60s计时器
          clearTimeout(this.timers);
          this.timeNums = 60;
          this.loading.close();
          this.jishi();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 在有邮箱的情况下修改邮箱
    // 第一步:利用旧的邮箱发送验证码
    // 必须传'changeemail'
    async XiuEmail() {
      this.timeNum = 0;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.email({
        email: this.youxiang,
        event: "checkemail",
      }).then((res) => {
        if (res.code == 1) {
          // 成功后进行下一步验证
          this.youxiangbuzhou = 1;
          // 开始60s计时器
          clearTimeout(this.timer);
          this.timeNum = 60;
          this.loading.close();
          this.sixty();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 没有邮箱的情况下修改邮箱
    // 发送邮箱验证码
    async emailAuth() {
      if (this.onepass == "") {
        return;
      }
      this.timeNum = 0;
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.email({
        email: this.onepass,
        event: "bindemail",
      }).then((res) => {
        if ((res.code = 1)) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          clearTimeout(this.timer);
          this.timeNum = 60;
          this.loading.close();
          this.sixty();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 绑定邮箱
    async bindEmail() {
      if (this.onepass == "") {
        return;
      }
      if (this.auth == "") {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.changeemail({
        email: this.onepass,
        captcha: this.auth,
      }).then((res) => {
        if (res.code == 1) {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$parent.yong();
          this.quxiao();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    jishi() {
      if (this.timeNums != 0) {
        this.timers = setTimeout(() => {
          this.timeNums--;
          this.jishi();
        }, 1000);
      }
    },
    // 验证码60s倒计时
    sixty() {
      if (this.timeNum != 0) {
        this.timer = setTimeout(() => {
          this.timeNum--;
          this.sixty();
        }, 1000);
      }
    },
    // 启用编辑
    bianji() {
      // this.jinyong = false
      this.xiusta = 1;
    },
    // 取消修改
    quxiao() {
      // this.jinyong = true
      this.xiusta = 0;
      this.onepass = "";
      this.twopass = "";
      this.auth = "";
      this.mimabuzhou = 0;
    },
  },
};
</script>

<style scoped lang="scss">
._nicheng {
  width: 100%;
}

.ziti {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 25px;
}

.account {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    color: #606266 !important;
  }
}

.fontOne {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #2670c5;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
}

.anniu {
  margin-top: 15px;
  padding-left: 88px;
}

.btnOne {
  // margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.btnTwo {
  margin-left: 14px;

  ::v-deep(.el-button) {
    width: 66px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.you {
  padding-left: 10px;

  .fontTwo {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.mei {
  padding-left: 12px;
}

.btnThree {
  margin-top: 10px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}

.diyici {
  width: 363px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6dbe3;
  border-radius: 2px;
  margin-right: 36px;
  margin-bottom: 15px;

  :deep(.el-input__inner) {
    border: 1px solid transparent;
    background: transparent;
    height: 45px;

    &::-webkit-input-placeholder {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}

.yanzheng {
  width: 180px !important;
  margin-right: 15px;
}

.btnfour {
  // margin-left: 15px;

  ::v-deep(.el-button) {
    width: 120px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 500;
    border: 0px solid red;
  }
}
</style>